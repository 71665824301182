import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import NotFoundPage from "../components/not-found/not-found"

const NotFound = () => (

  <Layout>
    <Seo title="404: Not found" />
    <NotFoundPage />
  </Layout>
)

export default NotFound
