import React from "react"
import Image from "../image-component/image-new"
import {  Link  } from "gatsby"
import "./not-found.scss"

const pageContent = {
    "heroImage":{
        "fallBackImage": "404_desktop.png",
        "responsiveImages": [
            {
                "imageName": "404_desktop.png",
                "minBreakpoint": "992px"
            },
            {
                "imageName": "404_tablet.png",
                "minBreakpoint": "768px"
            },
            {
                "imageName": "404_mobile.png",
                "minBreakpoint": "320px"
            }
        ]
    }
}

export default function NotFound() {
    
    return (  
        <>
            <section id="page_404_contant">
                <div className="container">
                    <Image sourceFolder={'not-found'} imgName={pageContent.heroImage.responsiveImages} fallBackImage={pageContent.heroImage.fallBackImage} />
                    <h1 className="main_title">Oops!<br/>A/B Test in Development</h1>
                    <p className="sub_title">The page you are looking for is still a secret.<br/>Lets get you back.</p>
                    <Link to="/" className="home_link" >back to home page</Link>
                </div>
            </section>
        </>
    )
}